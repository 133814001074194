
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code6 } from "./data";

export default defineComponent({
  name: "custom-template",
  data() {
    return {
      cities: [
        {
          value: "Beijing",
          label: "Beijing"
        },
        {
          value: "Shanghai",
          label: "Shanghai"
        },
        {
          value: "Nanjing",
          label: "Nanjing"
        },
        {
          value: "Chengdu",
          label: "Chengdu"
        },
        {
          value: "Shenzhen",
          label: "Shenzhen"
        },
        {
          value: "Guangzhou",
          label: "Guangzhou"
        }
      ],
      value: ""
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code6
    };
  }
});
