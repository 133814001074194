
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code10 } from "./data";

export default defineComponent({
  name: "create-new-items",
  data() {
    return {
      options: [
        {
          value: "HTML",
          label: "HTML"
        },
        {
          value: "CSS",
          label: "CSS"
        },
        {
          value: "JavaScript",
          label: "JavaScript"
        }
      ],
      value: []
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code10
    };
  }
});
